export enum COOKIE_KEYS {
  VIA_ACCESS_TOKEN = "via_access_token",
  INTERVIEW_TYPE = "via_interview_type",
  INTERVIEW_ID = "interview_id",
  STUDENT_DASHBOARD_URL = "student_dashboard_url",
  LOGROCKET_IDENTIFIER = "logrocket_identifier",
  VIA_USER_NAME = "via_user_name",
  VIA_IMPERSONATING_USER_NAME = "via_impersonating_user_name",
  VIA_USER_TYPE = "via_user_type",
}

export enum SUPPORTED_LANGUAGES {
  ENGLISH = "en",
  ARABIC = "ar",
  KOREAN = "ko_KR",
  CHINESE = "zh_CN",
}
