import { Component, ChangeDetectionStrategy } from "@angular/core";
import { UtilService } from "src/app/services/util/util.service";

@Component({
  selector: "via-back-to-dashboard-button",
  templateUrl: "./back-to-dashboard-button.component.html",
  styleUrls: ["./back-to-dashboard-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackToDashboardButtonComponent {
  constructor(public util: UtilService) {}
}
