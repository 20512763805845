import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { COOKIE_KEYS } from "./app.enum";

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  constructor(private _cookie: CookieService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (!request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }
    let { headers } = request;
    const accessToken = <string>(
      this._cookie.get(COOKIE_KEYS.VIA_ACCESS_TOKEN)
    );
    if (!accessToken) {
      throw new Error("Authorization token does not exist.");
    }
    headers = headers.set("Authorization", `Bearer ${accessToken}`);
    headers = headers.set("Accept-Language", "en");
    return next.handle(request.clone({ headers }));
  }
}
