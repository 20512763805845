<header id="record_interview__header">
  <section>
    <a
      [href]="(theme$ | async)?.student_dashboard?.host"
      target="ischoolconnect_landing_page"
      noreferrer
    >
      <div
        *ngIf="!(theme$ | async)?.account_settings?.logo_path"
        id="logo_loader"
      >
      </div>
      <img
        *ngIf="(theme$ | async)?.account_settings?.logo_path"
        id="record_interview__brand_logo"
        [src]="(theme$ | async)?.account_settings?.logo_path"
        alt="Brand logo"
      />
    </a>
  </section>
</header>
