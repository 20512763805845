import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth/auth.guard";
import { ConflictComponent } from "./modules/error/screens/conflict/conflict.component";
import { FatalErrorComponent } from "./modules/error/screens/fatal-error/fatal-error.component";
import { ForbiddenComponent } from "./modules/error/screens/forbidden/forbidden.component";
import { NetworkOfflineComponent } from "./modules/error/screens/network-offline/network-offline.component";
import { PageNotFoundComponent } from "./modules/error/screens/page-not-found/page-not-found.component";
import { VideoTooLargeComponent } from "./modules/error/screens/video-too-large/video-too-large.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/record-interview",
  },
  {
    path: "report",
    loadChildren: () =>
      import("./modules/report/report.module").then((m) => m.ReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: "record-interview",
    loadChildren: () =>
      import("./modules/record-interview/record-interview.module").then(
        (m) => m.RecordInterviewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "error",
    redirectTo: "error/page-not-found",
    pathMatch: "full",
  },
  {
    path: "error/conflict",
    component: ConflictComponent,
  },
  {
    path: "error/video-too-large",
    component: VideoTooLargeComponent,
  },
  {
    path: "error/fatal-error",
    component: FatalErrorComponent,
  },
  {
    path: "error/forbidden",
    component: ForbiddenComponent,
  },
  {
    path: "error/page-not-found",
    component: PageNotFoundComponent,
  },
  {
    path: "error/network-offline",
    component: NetworkOfflineComponent,
  },

  {
    path: "**",
    redirectTo: "error/page-not-found",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
