export enum RECORDER_LOCAL_STORAGE_KEYS {
  FINISHED_QUESTIONS_SET = "finished_questions_set",
  SIGNED_URL = "SIGNED_URL",
  LAST_RECORDED_INTERVIEW = "last_recorded_interview",
}

export enum INDEXED_DB_STORE_NAME {
  RECORDED_INTERVIEW = "recorded_interviews",
}

export enum RECORDER_COOKIE_KEYS {
  USER_COMPLIANT = "user_compliant",
  LANG = "lang",
  STUDENT_DASHBOARD_URL = "student_dashboard_url",
}
