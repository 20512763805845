/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { TranslocoService } from "@ngneat/transloco";
import { SUPPORTED_LANGUAGES } from "./app.enum";

export function TranslationInitializationFactory(transloco: TranslocoService) {
  return function () {
    return transloco.load(SUPPORTED_LANGUAGES.ENGLISH).toPromise();
  };
}
