export enum REPORT_SCREEN_TITLES {
    STRENGTHS = "strength",
    WEAKNESSES = "weaknesses",
    IMPROVEMENT_TIPS = "improvement_tips",
    FEEDBACK = "Feedback",
    YOUR_SCORE = "YOUR SCORE",
    YOUR_BEST_SCORE = "YOUR BEST SCORE",
    BEHAVIOURAL_TRAIT_ANALYSIS = "Bheavioural Trait Analysis",
    OVERALL_SUMMARY = "Overall Summary",
    QUESTIONS = "Questions",
    CONFIDENCE = "Confidence",
    FOCUS = "Focus",
    ENERGY = "Energy",
    COMPOSURE = "Composure"
}

export enum REPORT_LOCAL_STORAGE_KEYS {
  REPORTS = "via_reports",
  COMMENTS = "vie_comments",
  OPTIONS = "via_options",
  USER_NAME = "user_name",
  EDIT_REPORTS = "via_edit_reports",
  IS_EDITING = "via_edit_enabled"
}
