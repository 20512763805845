import { Component } from "@angular/core";

@Component({
  templateUrl: "./forbidden.component.html",
  styleUrls: ["./forbidden.component.scss"]
})
export class ForbiddenComponent {

  constructor() { }

}
