import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { UtilService } from "src/app/services/util/util.service";

@Component({
  selector: "via-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnDestroy {
  private readonly networkStateSubscription$: Subscription;

  constructor(private _util: UtilService) {
    this.networkStateSubscription$ = this._util
      .networkStateSubject$
      .subscribe();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.networkStateSubscription$.unsubscribe();
  }
}
