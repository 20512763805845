import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslocoRootModule } from "./transloco/transloco-root.module";
import { NG_ENTITY_SERVICE_CONFIG } from "@datorama/akita-ng-entity-service";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { environment } from "../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { indexedDbConfig } from "./indexed-db.config";
import { AddHeadersInterceptor } from "./add-headers.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorModule } from "./modules/error/error.module";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { SharedModule } from "./modules/shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { TranslocoService } from "@ngneat/transloco";
import { TranslationInitializationFactory } from "./app-initialization";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  // autoOpen: false,

  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
    },
    button: {
      background: "var(--color-brand)",
      text: "#fff",
    },
  },
  layout: "my-custom-layout",
  layouts: {
    "my-custom-layout": "{{messagelink}}{{compliance}}",
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message default-font text-md leading-md md:text-normal md:leading-normal">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link sm:text-left" href="{{cookiePolicyHref}}" target="_blank" rel="noopener"  >{{cookiePolicyLink}}.</a>
    </span>
    `,
  },
  content: {
    cookiePolicyHref: "https://ischoolconnect.com/privacy-policy",
  },
  type: "info",
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    ErrorModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxIndexedDBModule.forRoot(indexedDbConfig),
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      progressAnimation: "increasing",
      titleClass: "font-heading",
    }),
  ],
  // providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: TranslationInitializationFactory,
      deps: [TranslocoService],
      multi: true,
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: "https://jsonplaceholder.typicode.com" },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true,
    },
    CookieService,
  ],
})
export class AppModule {}
