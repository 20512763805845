import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { INDEXED_DB_STORE_NAME } from "src/app/modules/record-interview/record-interview.enum";
import { RecordInterviewApiService } from "src/app/modules/record-interview/services/record-interview/record-interview-api.service";

@Component({
  selector: "via-retake-interview-button",
  templateUrl: "./retake-interview-button.component.html",
  styleUrls: ["./retake-interview-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetakeInterviewButtonComponent  {
  constructor(
    private _router: Router,
    private _indexedDb: NgxIndexedDBService,
    private _interviewApi: RecordInterviewApiService
  ) {}

  async retakeInterview(): Promise<void> {
    try {
      await this._indexedDb
        .clear(INDEXED_DB_STORE_NAME.RECORDED_INTERVIEW)
        .toPromise();
      this._interviewApi.lastRecordedInterviewKey = 0;
      this._router.navigate(["/record-interview"]);
    } catch (error) {
      console.error(error);
    }
  }
}
