import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { Observable } from "rxjs";
import { concatMap, map, tap } from "rxjs/operators";
import { COOKIE_KEYS } from "src/app/app.enum";
import { ApiResponse, UUID } from "src/app/app.types";
import { environment } from "src/environments/environment";
import {
  INDEXED_DB_STORE_NAME,
  RECORDER_LOCAL_STORAGE_KEYS,
} from "../../record-interview.enum";
import {
  IDbInterviewRecorderSchema,
  SignedUrlResponse,
  UploadInterviewParams,
} from "../../record-interview.types";

type TSToFix = unknown;

/**
 * All API Calls w.r.t. the functioning of the Interview Recorder module.
 */
@Injectable({
  providedIn: "root",
})
export class RecordInterviewApiService {
  get lastRecordedInterviewKey(): number {
    return parseInt(localStorage.getItem(RECORDER_LOCAL_STORAGE_KEYS.LAST_RECORDED_INTERVIEW) ?? "0");
  }

  set lastRecordedInterviewKey(key: number) {
    localStorage.setItem(RECORDER_LOCAL_STORAGE_KEYS.LAST_RECORDED_INTERVIEW, key.toString());
  }

  get videoReuploadData(): SignedUrlResponse {
    return JSON.parse(
      localStorage.getItem(RECORDER_LOCAL_STORAGE_KEYS.SIGNED_URL) ?? "null"
    );
  }
  set videoReuploadData(data: SignedUrlResponse) {
    localStorage.setItem(
      RECORDER_LOCAL_STORAGE_KEYS.SIGNED_URL,
      JSON.stringify(data)
    );
  }

  private readonly apiUrl = environment.apiUrl;

  constructor(
    private _http: HttpClient,
    private _indexedDb: NgxIndexedDBService,
    private _cookie: CookieService
  ) {}

  /**
   * Fetches the signed url and it's relevant upload policies
   * @param interviewId The interview for which the upload url has to be fetched
   * @returns Details of the signed url
   */
  getVideoUploadURL(interviewId: UUID): Observable<SignedUrlResponse> {
    const url = this.makeURL(this.apiUrl, "interview/" + interviewId);

    return this._http
      .get<ApiResponse<{ signed_url: SignedUrlResponse }>>(url)
      .pipe(
        map((res) => {
          const {
            signed_url: { url, fields },
          } = res.data;

          return {
            url,
            fields,
          };
        }),
        tap((data) => {
          this.videoReuploadData = data;
        })
      );
  }

  /**
   * Gets the details of the recorded video
   */
  getInterviewDetails(): Observable<IDbInterviewRecorderSchema> {
    if (!this.lastRecordedInterviewKey) throw Error("No Interview Key found");
    //TODO:  IndexedDB already has interview id in it. we might as well stop storing it in iDB or not fetch here
    const interview_id = this._cookie.get(COOKIE_KEYS.INTERVIEW_ID);

    return this._indexedDb
      .getByID<IDbInterviewRecorderSchema>(
        INDEXED_DB_STORE_NAME.RECORDED_INTERVIEW,
        this.lastRecordedInterviewKey
      )
      .pipe(
        map((data) => {
          return {
            ...data,
            interview_id,
          };
        })
      );
  }

  // FIXME: THis has to work sequentially, not parallely
  uploadInterview({
    finishedQuestionsSet,
    interviewFile,
    interviewId,
    interviewUploadUrl,
  }: UploadInterviewParams): Observable<TSToFix> {
    const videoUpload$ = this.uploadVideo({
      interviewFile,
      interviewUploadUrl,
      interviewId,
    }).pipe();
    const metadataUpload$ = this.uploadVideoMetaData({
      interviewId,
      finishedQuestionsSet,
    });

    return videoUpload$.pipe(
      concatMap((uploadResponse) => {
        return metadataUpload$;
      })
    );
  }

  private uploadVideo({
    interviewFile,
    interviewId,
    interviewUploadUrl,
  }: Pick<
    UploadInterviewParams,
    "interviewFile" | "interviewId" | "interviewUploadUrl"
  >): Observable<TSToFix> {
    const { url, fields: policyFields } = interviewUploadUrl;
    const formData = new FormData();
    Object.entries(policyFields).forEach(([policyName, value]) => {
      formData.append(policyName, value);
    });

    formData.append("Content-Type", "video/webm");
    formData.append("file", interviewFile);
    return this._http.post(url, formData, {});
  }

  private uploadVideoMetaData({
    interviewId,
    finishedQuestionsSet,
  }: Pick<
    UploadInterviewParams,
    "finishedQuestionsSet" | "interviewId"
  >): Observable<ApiResponse> {
    const url = `${this.apiUrl}/interview/${interviewId}`;

    return this._http.post<ApiResponse>(url, finishedQuestionsSet);
  }

  makeURL(url: string, endpoint: string): string {
    return `${url}/${endpoint}`;
  }
}
