<footer class="footer">
  <div class="footer-text">
    <p class="text" *ngIf="isIschoolBrandingAllowed">
      Powered by <b class="fw-600">iSchoolConnect</b>
    </p>
  </div>
  <div class="help-text">
    <a
      [href]="landingPageUrl + '/how-it-works'"
      *ngIf="isIschoolBrandingAllowed"
      target="_blank"
      class="text-sm leading-sm text-gray-dark pl-2"
      >Need help?</a
    >
  </div>
</footer>
