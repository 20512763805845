import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { COOKIE_KEYS } from "src/app/app.enum";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _cookie: CookieService, private _router: Router) {}
  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): boolean {
    const hasRequiredData = Boolean(
      this._cookie.get(COOKIE_KEYS.INTERVIEW_TYPE) &&
        this._cookie.get(COOKIE_KEYS.INTERVIEW_ID) &&
        this._cookie.get(COOKIE_KEYS.VIA_ACCESS_TOKEN)
    );

    if (!hasRequiredData) {
      this._router.navigate(["/error/forbidden"]);
    }
    return hasRequiredData;
  }
}
