import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import LogRocket from "logrocket";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { COOKIE_KEYS } from "./app.enum";
import { RECORDER_COOKIE_KEYS } from "./modules/record-interview/record-interview.enum";
import { MetaService } from "./services/meta/meta.service";
import { UtilService } from "./services/util/util.service";

@Component({
  selector: "via-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  resolveReportLoader: boolean = false;
  constructor(
    private _cookie: CookieService,
    private _translocoService: TranslocoService,
    private _activatedRoute: ActivatedRoute,
    private _util: UtilService,
    private _meta: MetaService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    const logrocket =
      this._cookie.get(COOKIE_KEYS.LOGROCKET_IDENTIFIER) === "advisor"
        ? environment.advisorLogrocketId
        : environment.studentLogrocketId;

    LogRocket.init(logrocket, { rootHostname: "ischoolconnect.com" });
    this._activatedRoute.queryParams.subscribe((res) => {
      let currentLang = res?.lang;
      if (currentLang) {
        this._cookie.delete(RECORDER_COOKIE_KEYS.LANG);
        this._cookie.set(RECORDER_COOKIE_KEYS.LANG, currentLang);
        this._translocoService.setActiveLang(currentLang);
      } else {
        currentLang = this._cookie.get(RECORDER_COOKIE_KEYS.LANG);
        if (!currentLang) {
          currentLang = "en";
          this._cookie.set(RECORDER_COOKIE_KEYS.LANG, "en");
        }
        this._translocoService.setActiveLang(currentLang);
      }
      const htmlEl = this._document.querySelector("html");
      htmlEl?.setAttribute("lang", currentLang);
      if (currentLang === "ar") {
        htmlEl?.setAttribute("dir", "rtl");
      } else {
        htmlEl?.setAttribute("dir", "");
      }
    });
    this._util.showLoader$.subscribe((loader) => {
      this.resolveReportLoader = loader;
    });
  }
  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler() {
    window.parent.window.postMessage(
      { action: "close" },
      this._cookie.get(COOKIE_KEYS.STUDENT_DASHBOARD_URL)
    );
  }

  ngOnInit(): void {
      this._meta.fetchAndSetTheme();
  }
}
