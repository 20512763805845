<via-error>
  <article class="via__error_page_info_wrapper">
    <h1 class="via__error_page_code">404</h1>
    <p class="via__error_page_subtext">
      {{ "page_not_found.description" | transloco }}
    </p>
    <button class="via__error_page_back_button" (click)="goToDashboard()">
      {{ "button.home" | transloco }}
    </button>
  </article>
  <img
    class="via__error_page_artwork"
    src="{{artwork}}"
  />
</via-error>
