import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "via-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent {
  constructor(public _router: Router) {}

  artwork = `${environment.cdnBaseUrl}/common-files/404-artwork.png`;

  goToDashboard():void{
    this._router.navigate(["/"]);
  }
}
