import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MetaService, Theme } from "src/app/services/meta/meta.service";
import { UtilService } from "src/app/services/util/util.service";
import { ChangeDetectorRef } from "@angular/core";
@Component({
  selector: "via-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  constructor(
    private _utilService: UtilService,
    private _meta: MetaService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  landingPageUrl = this._utilService.landingPageBaseUrl;
  isIschoolBrandingAllowed = true;
  async ngOnInit() {
    const themeData: Theme = await this._meta.getTheme();
    this.isIschoolBrandingAllowed = themeData.isIscBrandingAllowed ?? true;
    //Facing some reactivity issues on this component. So added this change detector
    this.changeDetectorRef.detectChanges();
  }
}
