import {
  ChangeDetectionStrategy, Component, Input
} from "@angular/core";

@Component({
  selector: "via-error-actions",
  templateUrl: "./error-actions.component.html",
  styleUrls: ["./error-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorActionsComponent {
  @Input() showRetakeInterview = true;
  @Input() showRetryUpload = true;
  constructor() {}

}
