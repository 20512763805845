import { Component } from "@angular/core";

@Component({
  templateUrl: "./conflict.component.html",
  styleUrls: ["./conflict.component.scss"]
})
export class ConflictComponent {

  constructor() { }

}
