import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { NAVIGATOR } from "@ng-web-apis/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "via-retry-upload-button",
  templateUrl: "./retry-upload-button.component.html",
  styleUrls: ["./retry-upload-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryUploadButtonComponent {
  constructor(
    private _router: Router,
    @Inject(NAVIGATOR) private _navigator: Navigator,
    private _toastr: ToastrService
  ) {}
  async retryInterview(): Promise<void> {
    if (this._navigator.onLine) {
      this._router.navigate(["record-interview/recorder/upload"]);
    } else {
      this._toastr.warning("You are offline");
    }
  }
}
