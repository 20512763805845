import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LOCAL_STORAGE_KEYS } from "src/app/lib/constants/enums/key-names.enum";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../localstorage/localstorage.service";

interface HostMeta {
  account_settings: {
    brand_color: string;
    favicon_path: string;
    logo_path: string;
    logo_white_path: string;
    is_isc_branding_allowed: boolean;
  };
  application_settings: {
    is_facebook_login_enabled: boolean;
    is_google_login_enabled: boolean;
    is_self_registration_enabled: boolean;
  };
  fa_application_id: string;
  fa_tenant_id: string;
  student_dashboard: {
    fa_application_id: string;
    host: string;
  };
}

const defaultTheme = {
  account_settings: {
    brand_color: "hsla(196, 100%, 47%, 1)", // ISC colors
    favicon_path: "",
    logo_path: "",
    logo_white_path: "",
    is_isc_branding_allowed: true,
  },
  application_settings: {
    is_facebook_login_enabled: false,
    is_google_login_enabled: false,
    is_self_registration_enabled: false,
  },
  fa_application_id: "",
  fa_tenant_id: "",
  student_dashboard: {
    fa_application_id: "",
    host: "",
  },
};

export interface Theme {
  logoPath?: string;
  brandColor?: string;
  faviconPath?: string;
  logoWhitePath?: string;
  isIscBrandingAllowed?: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MetaService {
  constructor(
    private _http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  private updateFavicon(theme: HostMeta) {
    const faviconClass = document.getElementsByClassName("favicon-img");
    if (theme.account_settings.favicon_path) {
      Array.from(faviconClass).forEach((element) => {
        const hrefValue = `${theme.account_settings.favicon_path}`;
        element.setAttribute("href", hrefValue);
      });
    }
  }

  private updateColors(theme: HostMeta) {
    document.documentElement.style.setProperty(
      "--color-brand",
      theme.account_settings.brand_color
    );
  }

  public theme$ = new BehaviorSubject<HostMeta>(defaultTheme);

  public async fetchAndSetTheme(): Promise<HostMeta> {
    let headers = new HttpHeaders();
    headers = headers.set("x-tenant-host", document.location.origin);
    let hostMeta: HostMeta = {
      account_settings: {
        brand_color: "",
        favicon_path: "",
        logo_path: "",
        logo_white_path: "",
        is_isc_branding_allowed: true,
      },
      application_settings: {
        is_facebook_login_enabled: false,
        is_google_login_enabled: false,
        is_self_registration_enabled: false,
      },
      fa_application_id: "",
      fa_tenant_id: "",
      student_dashboard: {
        fa_application_id: "",
        host: "",
      },
    };
    try {
      const themeResponse = (await this._http
        .get(`${environment.advisorBackendUrl}/api/v0/setting/host-details`, {
          headers,
        })
        .toPromise()) as { data: HostMeta };
      this.theme$.next(themeResponse.data);
      this.updateFavicon(themeResponse.data);
      this.updateColors(themeResponse.data);
      await this.setTheme(themeResponse.data);
      hostMeta = themeResponse.data;
    } catch (error) {
      console.error("Failed to fetch the host-details API: ", error);
    }
    return hostMeta;
  }
  async setTheme({
    account_settings: {
      brand_color,
      favicon_path,
      logo_path,
      logo_white_path,
      is_isc_branding_allowed,
    },
  }: HostMeta): Promise<void> {
    this._localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, {
      brandColor: brand_color,
      logoPath: logo_path,
      logoWhitePath: logo_white_path,
      faviconPath: favicon_path,
      isIscBrandingAllowed: is_isc_branding_allowed,
    });
  }

  async getTheme(): Promise<Theme> {
    const theme = this._localStorage.getItem(
      LOCAL_STORAGE_KEYS.THEME,
      true
    ) as Theme;
    if (theme && Object.keys(theme).length !== 0) {
      return Promise.resolve(theme);
    }
    const response = await this.fetchAndSetTheme();

    return Promise.resolve({
      brandColor: response.account_settings.brand_color,
      logoPath: response.account_settings.logo_path,
      logoWhitePath: response.account_settings.logo_white_path,
      faviconPath: response.account_settings.favicon_path,
      isIscBrandingAllowed: response.account_settings.is_isc_branding_allowed,
    });
  }
}
