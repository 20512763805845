import { DBConfig } from "ngx-indexed-db";
import { INDEXED_DB_STORE_NAME } from "./modules/record-interview/record-interview.enum";

export const indexedDbConfig: DBConfig = {
  name: "isc_vie",
  version: 1,
  objectStoresMeta: [
    {
      storeConfig: { keyPath: "id", autoIncrement: true },
      store: INDEXED_DB_STORE_NAME.RECORDED_INTERVIEW,
      storeSchema: [
        {
          name: "interview_id",
          keypath: "interview_id",
          options: {
            unique: true,
          },
        },
        {
          name: "filename",
          keypath: "filename",
          options: {
            unique: true,
          },
        },
        {
          name: "blob",
          keypath: "blob",
          options: {
            unique: true,
          },
        },
        {
          name: "finished_questions_set",
          keypath: "finished_questions_set",
          options: {
            unique: false,
          },
        },
      ],
    },
  ],
};
