import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { TranslocoRootModule } from "@translocoRootModule";
import { BackToDashboardButtonComponent } from "./components/back-to-dashboard-button/back-to-dashboard-button.component";

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    BackToDashboardButtonComponent,
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    BackToDashboardButtonComponent,
  ],
  imports: [CommonModule, TranslocoRootModule],
})
export class SharedModule {}
