import { Component, ChangeDetectionStrategy } from "@angular/core";
import { MetaService } from "src/app/services/meta/meta.service";

@Component({
  selector: "via-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(private _meta: MetaService) { }
  theme$ = this._meta.theme$;
}
