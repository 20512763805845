import { Injectable } from "@angular/core";
import { LOCAL_STORAGE_KEYS } from "src/app/lib/constants/enums/key-names.enum";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private prefix = "";
  constructor() {}

  setItem(key: string, value: unknown): void {
    if (typeof value !== "string")
      localStorage.setItem(key, JSON.stringify(value));
    else localStorage.setItem(key, value);
  }

  getItem(key: string, isObj = false): unknown {
    return isObj
      ? JSON.parse(localStorage.getItem(key) ?? "{}")
      : localStorage.getItem(key);
  }
  setExpiry(ttl: number): void {
    if (this.prefix) {
      const expiry = new Date().getTime() + ttl * 1000;
      this.setData(LOCAL_STORAGE_KEYS.EXPIRY, expiry);
    }
  }

  /**
   *
   * @param keyName The key it is associated with. A prefix will be appended, if applicable.
   * @param value The actual value to be stored
   * @param primary deprecated: If the value applies to all applications within the domain, do this. No prefix will be attached.
   */
  setData(keyName: LOCAL_STORAGE_KEYS, value: unknown, primary = false): void {
    localStorage.setItem(
      keyName,
      typeof value === "string" ? value : JSON.stringify(value)
    );
  }

  /**
   * Get the data from localstorage after checking it's validity and other fun things.
   * @param keyName The key for the application. Prefix will be added automatically, if applicable.
   * @param primary deprecated: True if the key is not application specific.
   */
  getData(keyName: LOCAL_STORAGE_KEYS, primary = false): string | null {
    if (this.hasExpired()) {
      this.clearAll();
    }
    return localStorage.getItem(keyName);
  }

  private hasExpired(): boolean {
    const expiryString = localStorage.getItem("expiry");
    if (expiryString) {
      const expiry = parseInt(expiryString, 10);
      if (expiry < new Date().getTime()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Will clear all data in the local storage with the exception of auth specific tokens
   */
  clearAll(): void {
    const keysToNotDelete: string[] = [LOCAL_STORAGE_KEYS.THEME];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && !keysToNotDelete.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }
}
