import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

@Component({
  selector: "via-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() spinnerSize = 80;
  constructor() {}
}
