import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { RECORDER_COOKIE_KEYS } from "src/app/modules/record-interview/record-interview.enum";
import { REPORT_LOCAL_STORAGE_KEYS } from "src/app/modules/report/report.enum";
import { environment } from "src/environments/environment";
import { COOKIE_KEYS } from "src/app/app.enum";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  networkStateSubject$: Subject<"online" | "offline"> = new Subject();
  showLoader$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _router: Router,
    private _cookie: CookieService,
    private _toastr: ToastrService,
    private _translocoService: TranslocoService
  ) {}

  get isAdvisor(): boolean {
    return Boolean(
      this._cookie.get(COOKIE_KEYS.VIA_USER_TYPE)?.toLowerCase() === "advisor"
    );
  }
  registerNetworkHandler() {
    this._handleNetworkState().subscribe((value) => {
      this.networkStateSubject$.next(value);
    });
  }

  set loader(value: boolean) {
    this.showLoader$.next(value);
  }

  set userName(name: string) {
    localStorage.setItem(REPORT_LOCAL_STORAGE_KEYS.USER_NAME, name);
  }

  get userName(): string {
    return localStorage.getItem(REPORT_LOCAL_STORAGE_KEYS.USER_NAME)!;
  }

  get landingPageBaseUrl(): string {
    let currentLang = this._cookie.get(RECORDER_COOKIE_KEYS.LANG);
    switch (currentLang) {
      case "ko_KR":
        currentLang = "ko";
        break;

      case "zh_CN":
        currentLang = "zh";
        break;

      default:
        break;
    }
    return `${environment.landingPageUrl}/${currentLang ?? "en"}`;
  }

  get studentDashboardUrl() {
    return (
      this._cookie.get(RECORDER_COOKIE_KEYS.STUDENT_DASHBOARD_URL) ||
      "https://students.ischoolconnect.com/auth"
    );
  }

  handleError(err: HttpErrorResponse | unknown) {
    if (err instanceof HttpErrorResponse) {
      const { status } = err;
      switch (status) {
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.Forbidden: {
          this._router.navigate(["/error/forbidden"]);
          break;
        }
        case HttpStatusCode.Conflict: {
          this._router.navigate(["/error/conflict"]);
          break;
        }
        case HttpStatusCode.InternalServerError: {
          this._router.navigate(["/error/fatal-error"]);
          break;
        }
        case HttpStatusCode.NotFound: {
          this._router.navigate(["/error/page-not-found"]);
          break;
        }
      }
    }
  }
  //Need to make this function generic to show the error messages based different error codes
  showErrorMessages(err: HttpErrorResponse | unknown) {
    if (err instanceof HttpErrorResponse) {
      this._toastr.error(
        this._translocoService.translateObject(
          "notifications.something_went_wrong"
        )
      );
    }
  }

  checkIfScoreLessThanTen(value: number): boolean {
    if (value && value <= 10 && value >= 0) {
      return true;
    } else {
      return false;
    }
  }

  private _handleNetworkState(): Observable<"online" | "offline"> {
    return merge(
      fromEvent(window, "online").pipe(
        tap((d) => {
          this._toastr.success("You are back online!");
        }),
        map((event) => {
          return "online";
        })
      ),
      fromEvent(window, "offline").pipe(
        tap((d) => {
          this._toastr.warning("You are offline");
        }),
        map((event) => {
          return "offline";
        })
      )
    ) as Observable<"online" | "offline">; //TODO: This line has to be fixed.
  }
  // TODO: Move this to a common location
  // @HostListener("window:online", ["$event"])
  // handleOnlineState(e: any) {}

  // @HostListener("window:offline", ["$event"])
  // handleOfflineState(e: any) {}
}
