import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslocoRootModule } from "@translocoRootModule";
import { SharedModule } from "../shared/shared.module";
import { ErrorActionsComponent } from "./components/error-actions/error-actions.component";
import { RetakeInterviewButtonComponent } from "./components/retake-interview-button/retake-interview-button.component";
import { RetryUploadButtonComponent } from "./components/retry-upload-button/retry-upload-button.component";
import { ErrorComponent } from "./error.component";
import { ConflictComponent } from "./screens/conflict/conflict.component";
import { FatalErrorComponent } from "./screens/fatal-error/fatal-error.component";
import { ForbiddenComponent } from "./screens/forbidden/forbidden.component";
import { NetworkOfflineComponent } from "./screens/network-offline/network-offline.component";
import { PageNotFoundComponent } from "./screens/page-not-found/page-not-found.component";
import { VideoTooLargeComponent } from "./screens/video-too-large/video-too-large.component";

@NgModule({
  declarations: [
    PageNotFoundComponent,
    ErrorComponent,
    ConflictComponent,
    VideoTooLargeComponent,
    RetakeInterviewButtonComponent,
    RetryUploadButtonComponent,
    ErrorActionsComponent,
    ForbiddenComponent,
    FatalErrorComponent,
    NetworkOfflineComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    // ErrorRoutingModule,
    SharedModule,
    TranslocoRootModule,
  ],
  exports: [
    PageNotFoundComponent,
    ErrorComponent,
    ConflictComponent,
    VideoTooLargeComponent,
    RetakeInterviewButtonComponent,
    RetryUploadButtonComponent,
    ErrorActionsComponent,
    ForbiddenComponent,
    FatalErrorComponent,
    NetworkOfflineComponent,
  ],
})
export class ErrorModule {}
